/*
*
* Navigation Styles
*
*/
.Navigation_featuresContainer {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

.Navigation_featuresChildren {
  margin: 150px 2% 2% 2%;
  flex: 1 0 auto;
}

@media (max-width: 600px) {
  .Navigation_featuresChildren {
    margin: 150px 2% 2% 2%;
  }
}